<template>
    <div>
      <VideoFull @input-video="handleVideo" v-if="!showVideo"/>
      <div class="sectionContainer">
        <HeaderSection v-if="showVideo"/>
        <InfoSection v-if="showVideo"/>
        <InfoSection v-if="showVideo"/>
        <FooterSection v-if="showVideo"/>
        <RedesSection v-if="showVideo"/>
        <ContactCard/>
      </div>
  </div>
</template>

<script>
  import VideoFull from './components/VideoFull.vue'
  import InfoSection from './components/Info.vue'
  import FooterSection  from './components/Footer.vue'
  import RedesSection from './components/RedesSociales.vue';
  import HeaderSection from './components/Header.vue';
  export default {
    name: 'App',
    components: {
      VideoFull,
      InfoSection,
      FooterSection,
      RedesSection,
      HeaderSection,
    },
    data() {
      return {
        showVideo: false
      }
    },
    methods: {
      handleVideo(data){
        this.showVideo = data;
        // Activar panle rrss
        // document.getElementById('social-icons').classList.add('active');
      }
    }
  }
</script>

<style>
  body{
    margin: 0;
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }
  .sectionContainer{
    position: relative;
    padding-top: 10vh;
  }
</style>
