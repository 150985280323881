<template>
    <div class="header">
        <div class="languageContainer">
            <div class="languageSection">
                <button class="languageButton">ES</button>
                <button class="languageButton">EN</button>
            </div>
            <button class="shopButton">Tienda Online</button>
        </div>
        <a><img src="../assets/sheep.png" alt="Logo"></a>
    </div>

</template>

<script>
    export default {
    name: 'HeaderSection',
    };
</script>

<style scoped>
.header{
    width: 100%;
    position: fixed;
    top:0;
    background-color: rgba(0,0,0,0.4);
    display: flex;
}
.header{
        -webkit-animation-name:showHeader;
        animation-name:showHeader;
        transform:translateY(-100%);
        animation-delay: 1s;
        -webkit-animation-duration:1.5s;
        animation-duration:1.5s;
        -webkit-animation-iteration-count:1;
        animation-iteration-count:1;
        -webkit-animation-fill-mode:forwards;
        animation-fill-mode:forwards
    }
@keyframes showHeader{
    from{-webkit-transform:translateY(-100%);transform:translateY(-100%)
    }
        to{-webkit-transform:translateY(0%);transform:translateY(0%)
    }
}
.header img{
    height: 7vh;
}
.header a{
    display: flex;
    align-items: center;
}
.languageContainer{
    width: 100%;
    padding: 15px 35px;
    z-index: 10;
    position: relative;
    -webkit-transition: .5s;
    animation-delay: 2s;
    transition: .5s;
    display: flex;
}
.languageSection{
    display: flex;
}
.languageButton{
    color: white;
    font-size: 1.8vh;
    font-weight: 600;
    outline: none;
    padding: 5px 10px;
    background: none;
    border: none;
    border-right: 1px solid #fff;
    display: block;
    cursor: pointer;

}
.languageButton.active{
    font-weight: bold;
}
@media screen and (max-width: 600px) {
    .shopButton{
        font-size: 1.5vh;
    }
}
.shopButton{
    color: #fff;
    text-transform: uppercase;
    padding: 10px 20px;
    border: 1px solid #fff;
    background-color: transparent;
    border-radius: 18px;
    margin-left: 25px;
    position: relative;
    overflow: hidden;
    transition: all 750ms;
}
.shopButton:hover {
  color: #ffffff;
  transform: scale(1.1);
  outline: 2px solid #8cce4280;
  box-shadow: 4px 5px 17px -4px #71a73580;
}
.shopButton::before {
  content: "";
  position: absolute;
  left: -50px;
  top: 0;
  width: 0;
  height: 100%;
  background-color: #8aca4159;
  transform: skewX(45deg);
  z-index: -1;
  transition: width 750ms;
}
.shopButton:hover::before {
  width: 250%;
}

</style>
